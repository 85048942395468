import React, { useState } from 'react';
import {
  AppBar, Box, IconButton, Menu, MenuItem, Stack, Toolbar, Typography,
} from '@mui/material';
import {
  matchRoutes, RouteObject, useLocation, useNavigate,
} from 'react-router';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { Menu as MenuIcon } from '@mui/icons-material';
import { useAccount } from 'wagmi';
import { Connect } from './Connect.tsx';
import { routes } from '../routes/routes.tsx';

export const Header = observer(() => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [anchorElNav, setAnchorElNav] = useState<null | HTMLElement>(null);
  const { status } = useAccount();
  const location = useLocation();
  const [{ route }] = matchRoutes(routes, location) as { route: RouteObject }[];

  const handleOpenNavMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navItems = [
    { key: '1', label: t('label.header.retreeb'), href: 'http://www.retreeb.io/how-it-works-impact-launchpad ' },
    { key: '2', label: t('label.header.buyTreeb'), href: 'https://linktr.ee/Retreebbuy' },
    { key: '3', label: t('label.header.vote'), link: '/' },
    { key: '4', label: t('label.header.my-voting-profile'), link: '/claims' },
  ];

  if (status !== 'connected') {
    navItems.splice(3, 1);
  }

  const action = (item: {link?: string, href?: string}) => {
    if (item.link) {
      navigate(item.link);
    } else if (item.href) {
      window.open(item.href, '_blank');
    }
  };

  return (
    <AppBar>
      <Toolbar>
        <Box sx={{ flexGrow: 0, display: { xs: 'flex', md: 'none' } }}>
          <IconButton
            size="large"
            aria-label="account of current user"
            aria-controls="menu-appbar"
            aria-haspopup="true"
            onClick={handleOpenNavMenu}
            color="inherit"
          >
            <MenuIcon />
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorElNav}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            open={Boolean(anchorElNav)}
            onClose={handleCloseNavMenu}
            sx={{
              display: { xs: 'block', md: 'none' },
            }}
          >
            {navItems.map((item) => (
              <MenuItem key={item.key} onClick={() => action(item)}>
                <Typography textAlign="center">{item.label}</Typography>
              </MenuItem>
            ))}
          </Menu>
        </Box>
        <Stack direction="row" gap={2} alignItems="center" justifyContent={{ xs: 'center', md: 'start' }} sx={{ cursor: 'pointer' }} flex={1} onClick={() => navigate('/')}>
          <img src="/images/retreeb.svg" alt="logo" height={36} />
        </Stack>
        <Stack display={{ xs: 'none', md: 'flex' }} mr={4} direction="row" gap={5} alignItems="center">
          {navItems.map((item) => (
            <Typography
              key={item.key}
              variant="body1"
              sx={{
                textDecoration: item.link === route.path ? 'underline' : 'none',
                textTransform: 'uppercase',
                color: '#fff',
                cursor: 'pointer',
                fontFamily: '"Azeret", sans-serif',
                fontSize: 13,
                fontWeight: 600,
              }}
              onClick={() => action(item)}
            >
              {item.label}
            </Typography>
          ))}
        </Stack>
        <Connect />
      </Toolbar>
    </AppBar>
  );
});
