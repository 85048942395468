import * as React from 'react';
import { createContext, useContext, useState } from 'react';
import { Snackbar } from '@mui/material';

const Context = createContext<(message: string | undefined) => void>(() => {});

export const useSnackbar = () => useContext(Context);

export type SnackbarProviderProps = {
  children: React.ReactNode
}

export const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
  const [snackbar, setSnackbar] = useState<string | undefined>();

  return (
    <Context.Provider value={setSnackbar}>
      <Snackbar
        open={Boolean(snackbar)}
        message={snackbar}
        onClose={() => setSnackbar(undefined)}
      />
      {children}
    </Context.Provider>
  );
};
