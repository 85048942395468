import { switchNetwork } from '@wagmi/core';
import { useEffect, useState } from 'react';
import {
  useAccount, useConnect, useDisconnect, useEnsName,
} from 'wagmi';
import {
  Avatar, Dialog, DialogTitle, IconButton, Stack, useMediaQuery,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';
import copy from 'copy-to-clipboard';
import { chain, config } from '../config/wagmi.ts';
import { useSnackbar } from './SnackbarProvider.tsx';

type ConnectProps = {
  connectOnly?: boolean;
};

export const Connect = ({ connectOnly }: ConnectProps) => {
  const {
    status: accountStatus, chainId, address, connector,
  } = useAccount();

  const { connectors, connect } = useConnect();
  const { disconnect } = useDisconnect();
  const { data: ensName } = useEnsName({ address });
  const setSnackbar = useSnackbar();
  const largeScreen = useMediaQuery('(min-width:900px)');
  const [dialogOpen, setDialogOpen] = useState(false);

  const { t } = useTranslation();

  useEffect(() => {
    if (chainId && chainId !== chain.id) {
      switchNetwork(config, { chainId: chain.id });
      setSnackbar(t('label.connect.switch', { chain: chain.name }));
    } else if (accountStatus === 'connecting' || accountStatus === 'reconnecting') {
      setSnackbar(t('label.connect.connecting'));
    } else {
      setSnackbar(undefined);
    }
  }, [chainId, accountStatus, t, setSnackbar]);

  return (
    <Stack direction="row" gap={4}>
      {accountStatus === 'connected' && !connectOnly && (
        largeScreen ? (
          <>
            <Button
              startIcon={connector && (
              <img height={24} src={connector.icon} alt={connector.name} />
              )}
              variant="outlined"
              onClick={() => {
                copy(address);
                setSnackbar(t('label.connect.copied'));
              }}
            >
              <span style={{ width: 100, textOverflow: 'ellipsis', overflow: 'hidden' }}>{address && ensName ? `${ensName} (${address})` : address}</span>
            </Button>
            <Button variant="contained" color="primary" onClick={() => disconnect()}>
              {t('label.connect.disconnect')}
            </Button>
          </>
        ) : (
          <IconButton onClick={() => disconnect()} sx={{ p: 0 }}>
            <Avatar sx={{ bgcolor: 'green' }} variant="square" alt={connector.name} src={connector.icon} />
          </IconButton>
        )
      )}
      {accountStatus === 'disconnected' && connectors.length === 1 && (
        largeScreen ? (
          <Button
            variant="contained"
            color="primary"
            key={connectors[0].uid}
            onClick={() => connect({ connector: connectors[0] })}
            startIcon={connectors[0].icon && (
              <img height={24} src={connectors[0].icon} alt={connectors[0].name} />
            )}
          >
            {t('label.connect.connect', { name: connectors[0].name })}
          </Button>
        ) : (
          <IconButton onClick={() => connect({ connector: connectors[0] })} sx={{ p: 0 }}>
            <Avatar sx={{ bgcolor: 'red' }} variant="square" alt={connectors[0].name} src={connectors[0].icon} />
          </IconButton>
        )
      )}
      {accountStatus === 'disconnected' && connectors.length > 1 && (
        <>
          <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)}>
            <DialogTitle>Select wallet</DialogTitle>
            <Stack gap={2} m={2}>
              { connectors.map((currentConnector) => (
                <Button
                  variant="outlined"
                  color="primary"
                  key={currentConnector.uid}
                  onClick={() => connect({ connector: currentConnector })}
                  startIcon={currentConnector.icon && (
                    <img height={24} src={currentConnector.icon} alt={currentConnector.name} />
                  )}
                >
                  {t('label.connect.connect', { name: currentConnector.name })}
                </Button>
              ))}
            </Stack>
          </Dialog>
          {largeScreen ? (
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setDialogOpen(true);
              }}
            >
              {t('label.connect.connect-wallet')}
            </Button>
          ) : (
            <IconButton onClick={() => setDialogOpen(true)} sx={{ p: 0 }}>
              <Avatar sx={{ bgcolor: 'red' }} variant="square" />
            </IconButton>
          )}
        </>
      )}
    </Stack>
  );
};
