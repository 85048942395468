import { observer } from 'mobx-react-lite';
import { Address } from 'abitype';
import Grid from '@mui/system/Unstable_Grid';
import { ProjectCard } from './ProjectCard.tsx';
import { ProjectCandidate } from '../types/elections-store.ts';
import { ContractInfo } from '../types/projects-store.ts';

type ProjectsProps = {
  projects: ProjectCandidate[];
  contractsInfo?: { [keys: Address]: ContractInfo };
  canVote?: boolean;
  onSelectProjectCandidate: (project: ProjectCandidate | undefined) => void;
};

export const Projects = observer((
  {
    projects,
    contractsInfo,
    canVote,
    onSelectProjectCandidate,
  }: ProjectsProps,
) => (
  <Grid container spacing={2} flex={3} m={{ sx: 0, md: 1 }}>
    {projects.map((projectCandidate) => (
      <Grid key={projectCandidate.id} xs={12} md={6} lg={4}>
        <ProjectCard
          projectCandidate={projectCandidate}
          contractInfo={contractsInfo?.[projectCandidate.contractAddress]}
          canVote={canVote}
          onSwitchProject={() => {
            onSelectProjectCandidate(projectCandidate);
          }}
        />
      </Grid>
    ))}
  </Grid>
));
