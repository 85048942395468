import { createConfig, http } from 'wagmi';
import { fantom, polygonAmoy } from 'viem/chains';

const chains = {
  [fantom.id]: fantom,
  [polygonAmoy.id]: polygonAmoy,
};

export const chain = chains[import.meta.env.VITE_CHAIN_ID];

export const config = createConfig({
  // chains: [fantom],
  chains: [chain],
  transports: {
    [fantom.id]: http(),
    [polygonAmoy.id]: http(),
  },
});

declare module 'wagmi' {
  interface register {
    config: typeof config;
  }
}
