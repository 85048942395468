import { observer } from 'mobx-react-lite';
import {
  Box, Card, CardContent, CardHeader, CardMedia, Stack, Typography,
} from '@mui/material';
import Carousel from 'react-material-ui-carousel';
import { useTranslation } from 'react-i18next';
import { marked } from 'marked';
import { useEffect, useState } from 'react';
import { Address } from 'abitype';
import { SelectedProjectCard } from './SelectedProjectCard.tsx';
import { ProjectCandidate } from '../types/elections-store.ts';
import { ContractInfo } from '../types/projects-store.ts';
import { SDGCard } from './SDGCard.tsx';

type ViewProjectProps = {
  projectCandidate: ProjectCandidate;
  canVote?: boolean;
  contractsInfo?: {[key: Address]: ContractInfo};
};

export const ViewProject = observer(({
  projectCandidate, canVote, contractsInfo,
}: ViewProjectProps) => {
  const { project } = projectCandidate;
  const { i18n } = useTranslation();

  const [description, setDescription] = useState<string>('');
  const [mediumDescription, setMediumDescription] = useState<string>('');

  useEffect(() => {
    marked.parse(project.description[i18n.language as 'en' | 'fr'], {
      async: true,
    }).then(setDescription);
    if (project.mediumDescription) {
      marked.parse(project.mediumDescription[i18n.language as 'en' | 'fr'], {
        async: true,
      }).then(setMediumDescription);
    }
  }, [i18n.language, project.description, project.mediumDescription]);

  return (
    <Box display="flex" gap={2} alignItems="flex-start" overflow="hidden" mb={2} flexDirection={{ xs: 'column-reverse', md: 'row' }}>
      <Box sx={{ flex: 2 }} overflow="hidden" height="100%">
        <Stack gap={2}>
          <Card>
            <CardHeader sx={{ textAlign: 'center' }} title={project.name[i18n.language as 'en' | 'fr']} />
            <CardContent>
              <Typography textAlign="center" className="unreset" dangerouslySetInnerHTML={{ __html: mediumDescription }} />
            </CardContent>
          </Card>

          <Carousel height={350}>
            {
            project.pictures && project.pictures.map((item) => (
              <CardMedia
                key={item.id}
                sx={{ height: 350, borderRadius: 1 }}
                component="img"
                image={item.assetPublicURL}
                alt={project.name.en}
              />
            ))
          }
          </Carousel>
          <Card>
            <CardContent sx={{ '& a': { color: '#FFC8AA' } }}>
              <Typography className="unreset" dangerouslySetInnerHTML={{ __html: description }} />
            </CardContent>
          </Card>
        </Stack>
      </Box>

      <Stack gap={2} flex={1} width={{ xs: '100%' }}>
        <SelectedProjectCard
          projectCandidate={projectCandidate}
          canVote={canVote}
          contractsInfo={contractsInfo}
        />
        <SDGCard projectCandidate={projectCandidate} />
      </Stack>
    </Box>
  );
});
