import { Trans, useTranslation } from 'react-i18next';
import {
  Box, Stack, Step, StepButton, StepLabel, Stepper, Typography, useMediaQuery,
} from '@mui/material';
import { DateRange, Layers, StarSharp } from '@mui/icons-material';
import { useConnect } from 'wagmi';
import { Election, ProjectCandidate } from '../types/elections-store.ts';

type HomeHeaderProps = {
  election: Election;
  activeStep: number;
  invertedColor?: boolean;
  onSelectProjectCandidate: (projectCandidate: ProjectCandidate | undefined) => void;
};
export const HomeHeader = ({
  election, activeStep, invertedColor = false, onSelectProjectCandidate,
}: HomeHeaderProps) => {
  const { t, i18n } = useTranslation();
  const largeScreen = useMediaQuery('(min-width:900px)');
  const { connectors } = useConnect();
  return (
    <Stack gap={2} px={{ xs: 1, lg: 16 }} color={invertedColor ? 'common.white' : 'text.primary'}>
      <Typography variant="h3">{t('label.home.title', { election: election.name[i18n.language as 'en' | 'fr'] })}</Typography>
      <Stack direction={{ xs: 'column', md: 'row' }} gap={{ xs: 0, md: 2 }}>
        <Stack direction="row" alignItems="center" gap={1}>
          <DateRange />
          <Typography>
            {new Date(election.startDate).toLocaleDateString(
              i18n.language,
              { day: 'numeric', month: 'long', year: 'numeric' },
            )}
            {' '}
            -
            {' '}
            {new Date(election.endDate).toLocaleDateString(
              i18n.language,
              { day: 'numeric', month: 'long', year: 'numeric' },
            )}
          </Typography>
        </Stack>

        <Stack direction="row" gap={{ xs: 2 }}>
          <Stack direction="row" alignItems="center" gap={1}>
            <Layers />
            <Typography>{t('label.home.projects-count', { count: election.projectCandidates.length })}</Typography>
          </Stack>

          <Stack direction="row" alignItems="center" gap={1}>
            <StarSharp />
            <Typography>{t('label.home.winners-count', { count: election.winnersSeatsCount })}</Typography>
          </Stack>
        </Stack>
      </Stack>

      {connectors.length === 0 && (
        <Typography sx={{ '& a': { color: '#FFC8AA' } }}>
          <Trans i18nKey="label.home.install-metamask">
            Please install
            <a href="https://metamask.io/">metamask</a>
            to be able to vote
          </Trans>
        </Typography>
      )}

      <Box mt={{ xs: 1, md: 4 }} mb={{ xs: 1, md: 4 }} display="flex" flexDirection="row" alignItems="center">
        <Box flex={1}>
          <Stepper activeStep={activeStep} alternativeLabel={!largeScreen}>
            <Step>
              {activeStep === 0 ? (
                <StepLabel color={invertedColor ? 'common.white' : ''}>{t('label.home.stepper.select')}</StepLabel>
              ) : (
                <StepButton onClick={() => onSelectProjectCandidate(undefined)}>
                  <StepLabel color={invertedColor ? 'common.white' : ''}>{t('label.home.stepper.select')}</StepLabel>
                </StepButton>
              )}
            </Step>
            <Step>
              <StepLabel color={invertedColor ? 'common.white' : ''}>{t('label.home.stepper.stake')}</StepLabel>
            </Step>
            <Step>
              <StepLabel color={invertedColor ? 'common.white' : ''}>{t('label.home.stepper.validate')}</StepLabel>
            </Step>
          </Stepper>
        </Box>
      </Box>
    </Stack>
  );
};
