import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { observer } from 'mobx-react-lite';
import { UseQueryResult } from '@tanstack/react-query';
import { Address } from 'abitype';
import {
  Box, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography,
} from '@mui/material';
import { Outlet, useMatch, useNavigate } from 'react-router';
import Button from '@mui/material/Button';
import { Trans, useTranslation } from 'react-i18next';
import { Transactions } from '../components/Transactions.tsx';
import { useReadBalance } from '../contracts/token-service.ts';
import { Layout } from '../components/Layout.tsx';
import { Election, ProjectCandidate } from '../types/elections-store.ts';
import { usePoolContracts } from '../contracts/pool-service.ts';
import { MINIMUM_AMOUNT_TO_VOTE } from '../constants.ts';
import { HomeHeader } from '../components/HomeHeader.tsx';
import { useCurrentElection } from '../api/useCurrentElection.tsx';
import { ContractInfo } from '../types/projects-store.ts';
import { theme } from '../config/mui.ts';

export type HomeContext = {
  election: Election,
  contractsInfo: { [key: Address]: ContractInfo},
  canVote?: boolean,
  onSelectProjectCandidate: (projectCandidate: ProjectCandidate | undefined) => void,
  activeStep: number,
  setActiveStep: (step: number) => void
}

export const Home = observer(() => {
  const [activeStep, setActiveStep] = useState(0);
  const { data: userBalance } = useReadBalance();
  const [viewBuyDialog, setViewBuyDialog] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { data: election, isLoading }: UseQueryResult<Election> = useCurrentElection();
  const { data: contractsInfo, refetch: refetchPool } = usePoolContracts(election
    ? election.projectCandidates.map((p) => p.contractAddress as Address)
    : []);

  const onSelectProjectCandidate = useCallback((projectCandidate: ProjectCandidate | undefined) => {
    if (projectCandidate) {
      const contract = contractsInfo && contractsInfo[projectCandidate.contractAddress];
      if (contract && contract.allowance > 0) {
        setActiveStep(2);
      } else {
        setActiveStep(1);
      }
      navigate(`/project/${projectCandidate.id}`);
    } else {
      setActiveStep(0);
      navigate('/');
    }
  }, [contractsInfo, setActiveStep, navigate]);

  const projectMatch = useMatch('/project/:id');
  const selectedProjectCandidate = projectMatch
    && election
    && election.projectCandidates.find((p) => p.id === projectMatch.params.id);

  const canVote = useMemo(() => {
    if (contractsInfo !== undefined && userBalance !== undefined) {
      const totalStaked = Object.keys(contractsInfo || {}).reduce((acc, key) => (
        contractsInfo[key as Address].amountStaked
          ? acc + contractsInfo[key as Address].amountStaked
          : acc
      ), 0);

      return Boolean(totalStaked + userBalance >= MINIMUM_AMOUNT_TO_VOTE);
    }

    return undefined;
  }, [contractsInfo, userBalance]);

  useEffect(() => {
    if (canVote === false) {
      setViewBuyDialog(true);
    }
  }, [canVote]);

  const picture = selectedProjectCandidate?.project?.picture;
  return (
    <>
      {picture && (
        <Box sx={{
          position: 'absolute',
          top: 80,
          zIndex: -1000,
          width: '100%',
          height: 400,
          overflow: 'hidden',
          backgroundImage: `linear-gradient(0deg, rgba(29,29,27,0) 0%, rgba(29,29,27,1) 100%), url('${picture?.assetPublicURL}')`,
        }}
        />
      )}

      <Layout overflow="auto">
        <Transactions onUpdate={refetchPool} />
        {election && (
          <Box>
            <HomeHeader
              invertedColor={Boolean(picture)}
              election={election}
              activeStep={activeStep}
              onSelectProjectCandidate={onSelectProjectCandidate}
            />

            <Outlet context={{
              election,
              contractsInfo,
              canVote,
              onSelectProjectCandidate,
              activeStep,
              setActiveStep,
            }}
            />

            <Dialog
              maxWidth="xs"
              open={viewBuyDialog}
              onClose={() => {
                setViewBuyDialog(false);
              }}
            >
              <DialogTitle id="alert-dialog-title">
                <Typography variant="h5" textAlign="center">
                  {' '}
                  {t('label.home.buy-alert.title')}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Box pr={4} pl={4}>
                  <DialogContentText id="alert-dialog-description" textAlign="center" color={theme.palette.text.primary}>
                    <Trans i18nKey="label.home.buy-alert.text" values={{ minimum: Math.round(MINIMUM_AMOUNT_TO_VOTE * 1e-18) }}>
                      1
                      <Typography fontWeight="bold" component="span">2</Typography>
                      3
                    </Trans>
                  </DialogContentText>
                </Box>
              </DialogContent>
              <DialogActions sx={{ justifyContent: 'center' }}>
                <Button
                  variant="outlined"
                  autoFocus
                  onClick={() => {
                    setViewBuyDialog(false);
                  }}
                >
                  {t('label.home.buy-alert.review')}
                </Button>
                <Button
                  variant="contained"
                  autoFocus
                  onClick={() => {
                    window.open('https://linktr.ee/Retreebbuy', '_blank');
                  }}
                >
                  {t('label.home.buy-alert.buy')}
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        )}
        {!election && !isLoading && (
          <Box>
            <Typography variant="h3">
              {' '}
              {t('label.home.no-election')}
            </Typography>
          </Box>
        )}
      </Layout>
    </>
  );
});
