import { useQuery } from '@tanstack/react-query';
import { projectApi } from './request.ts';
import { Election } from '../types/elections-store.ts';

export function useCurrentElection() {
  return useQuery<Election>({
    queryKey: ['currentElection'],
    queryFn: () => projectApi.get<Election>('/elections/current').then((res) => res.data),
  });
}
