import { Address } from 'abitype';
import { erc20Abi } from 'viem';
import { useAccount, useReadContract, useReadContracts } from 'wagmi';
import { TOKEN_CONTRACT } from '../config/token-contract.ts';
import { ContractInfo } from '../types/projects-store.ts';
import { stakingPlatformAbi as abi } from './abis.tsx';
import { useWriteContractAndStoreTransaction } from './helpers.ts';

const CUSTOM_YEAR_DURATION = 365 * 24 * 3600;

export const usePoolContracts = (addresses: Address[]) => {
  const { address: user } = useAccount();
  const tokenAddress = TOKEN_CONTRACT[import.meta.env.VITE_CHAIN_ID];
  const contracts = addresses.flatMap((address) => [
    {
      abi, address, functionName: 'amountStaked', args: [user],
    },
    { abi, address, functionName: 'endPeriod' },
    { abi, address, functionName: 'fixedAPY' },
    { abi, address, functionName: 'lockupDuration' },
    { abi, address, functionName: 'lockupPeriod' },
    {
      abi, address, functionName: 'rewardOf', args: [user],
    },
    {
      abi, address, functionName: 'staked', args: [user],
    },
    { abi, address, functionName: 'stakingDuration' },
    { abi, address, functionName: 'stakingMax' },
    { abi, address, functionName: 'startPeriod' },
    { abi, address, functionName: 'token' },
    { abi, address, functionName: 'totalDeposited' },
    {
      abi: erc20Abi, address: tokenAddress, functionName: 'allowance', args: [user, address],
    },
  ]);
  return useReadContracts({
    contracts,
    query: {
      select: (res) => {
        const obj: { [key: Address]: ContractInfo } = {};

        let totalDepositedForAllContracts = 0;

        for (let i = 0; i < res.length; i += 13) {
          if (!res.slice(i, i + 13).some((r) => r.error)) {
            obj[contracts[i].address] = {
              amountStaked: Number(res[i].result),
              endPeriod: new Date(Number(res[i + 1].result) * 1000),
              fixedAPY: Math.floor(
                Number(res[i + 2].result) * CUSTOM_YEAR_DURATION / Number(res[i + 7].result),
              ),
              lockupDuration: Number(res[i + 3].result),
              lockupPeriod: new Date(Number(res[i + 4].result) * 1000),
              rewardOf: Number(res[i + 5].result),
              staked: Number(res[i + 6].result),
              stakingDuration: Number(res[i + 7].result),
              stakingMax: Number(res[i + 8].result),
              startPeriod: new Date(Number(res[i + 9].result) * 1000),
              token: String(res[i + 10].result),
              totalDeposited: Number(res[i + 11].result),
              allowance: Number(res[i + 12].result),
              percentage: 0,
            };

            totalDepositedForAllContracts += Number(res[i + 11].result);
          }
        }

        for (let i = 0; i < res.length; i += 13) {
          if (obj[contracts[i].address]) {
            obj[contracts[i].address].percentage = obj[contracts[i].address].totalDeposited
              ? (obj[contracts[i].address].totalDeposited * 100) / totalDepositedForAllContracts
              : 0;
          }
        }

        return obj;
      },
      enabled: true,
    },
  });
};

export const useReadAmountStaked = (
  address: Address,
  { stakeHolder }: { stakeHolder: Address },
) => useReadContract({
  abi,
  address,
  functionName: 'amountStaked',
  args: [stakeHolder],
});

export const useReadEndPeriod = (address: Address) => useReadContract({
  abi,
  address,
  functionName: 'endPeriod',
});

export const useReadFixedAPY = (address: Address) => useReadContract({
  abi,
  address,
  functionName: 'fixedAPY',
});

export const useReadLockupDuration = (address: Address) => useReadContract({
  abi,
  address,
  functionName: 'lockupDuration',
});

export const useReadLockupPeriod = (address: Address) => useReadContract({
  abi,
  address,
  functionName: 'lockupPeriod',
});

export const useReadReward = (address: Address) => {
  const account = useAccount();
  return useReadContract({
    abi,
    address,
    functionName: 'rewardOf',
    args: [account.address as Address],
    query: { enabled: Boolean(account.address) },
  });
};

export const useReadStaked = (address: Address) => {
  const account = useAccount();
  return useReadContract({
    abi,
    address,
    functionName: 'staked',
    args: [account.address as Address],
    query: { enabled: Boolean(account.address) },
  });
};
export const useReadStakingDuration = (address: Address) => useReadContract({
  abi,
  address,
  functionName: 'stakingDuration',
});

export const useReadStakingMax = (address: Address) => useReadContract({
  abi,
  address,
  functionName: 'stakingMax',
});

export const useReadStartPeriod = (address: Address) => useReadContract({
  abi,
  address,
  functionName: 'startPeriod',
});

export const useReadToken = (address: Address) => useReadContract({
  abi,
  address,
  functionName: 'token',
});

export const useReadTotalDeposited = (address: Address) => useReadContract({
  abi,
  address,
  functionName: 'totalDeposited',
});

export const useWriteClaimRewards = () => {
  const { writeContractAsync } = useWriteContractAndStoreTransaction('CLAIM_REWARDS');

  return (
    { address }: { address: Address },
  ) => writeContractAsync(
    {
      abi,
      address,
      functionName: 'claimRewards',
    },
  );
};

export const useWriteDeposit = () => {
  const { writeContractAsync } = useWriteContractAndStoreTransaction('DEPOSIT');
  return (
    { address, amount }: { address: Address, amount: bigint },
  ) => writeContractAsync(
    {
      abi,
      address,
      functionName: 'deposit',
      args: [amount],
    },
  );
};

export const useWriteStartStaking = (address: Address) => {
  const { writeContractAsync } = useWriteContractAndStoreTransaction('START_STAKING');
  return () => writeContractAsync(
    {
      abi,
      address,
      functionName: 'startStaking',
    },
  );
};

export const useWriteWithdraw = (address: Address) => {
  const { writeContractAsync } = useWriteContractAndStoreTransaction('WITHDRAW');
  return (
    { amount }: { amount: bigint },
  ) => writeContractAsync(
    {
      abi,
      address,
      functionName: 'withdraw',
      args: [amount],
    },
  );
};

export const useWriteWithdrawAll = () => {
  const { writeContractAsync } = useWriteContractAndStoreTransaction('WITHDRAW_ALL');
  return (
    { address }: { address: Address },
  ) => writeContractAsync(
    {
      abi,
      address,
      functionName: 'withdrawAll',
    },
  );
};

export const useWriteWithdrawResidualBalance = (address: Address) => {
  const { writeContractAsync } = useWriteContractAndStoreTransaction('WITHDRAW_RESIDUAL_BALANCE');
  return () => writeContractAsync(
    {
      abi,
      address,
      functionName: 'withdrawResidualBalance',
    },
  );
};
