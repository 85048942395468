import { useOutletContext } from 'react-router';
import { useEffect } from 'react';
import { Projects } from '../components/Projects.tsx';
import { HomeContext } from './Home.tsx';

export const HomeSelect = () => {
  const {
    election, contractsInfo, canVote, onSelectProjectCandidate, setActiveStep,
  } = useOutletContext<HomeContext>();

  useEffect(() => {
    setActiveStep(0);
  }, [setActiveStep]);

  return (
    <Projects
      projects={election.projectCandidates}
      contractsInfo={contractsInfo}
      canVote={canVote}
      onSelectProjectCandidate={onSelectProjectCandidate}
    />
  );
};
