import { Navigate } from 'react-router';
import { useAccount } from 'wagmi';
import { observer } from 'mobx-react-lite';
import { Connect } from '../components/Connect.tsx';
import { config } from '../config/wagmi.ts';

export const Login = observer(() => {
  const { status: accountStatus, chainId } = useAccount();

  if (accountStatus === 'connected' && chainId === config.chains[0].id) {
    return <Navigate to="/" replace />;
  }

  return <Connect />;
});
