import { Trans, useTranslation } from 'react-i18next';
import { UseQueryResult } from '@tanstack/react-query';
import { Address } from 'abitype';
import {
  Box, Dialog, DialogActions, DialogContent, Divider, Stack, Typography,
} from '@mui/material';
import { PieChart } from '@mui/x-charts';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';
import { ReactNode } from 'react';
import { formatAmount } from '../utils.tsx';
import { usePoolContracts } from '../contracts/pool-service.ts';
import { useCurrentElection } from '../api/useCurrentElection.tsx';
import { Election, ProjectCandidate } from '../types/elections-store.ts';
import { colors } from '../config/mui.ts';

type VoteConfirmationDialogProps = {
  projectCandidate: ProjectCandidate;
  amount: bigint;
  open: boolean;
  onClose: () => void;
};

const palette = Object.values(colors);

export const InfoRow = ({ label, value }: {label:string, value: ReactNode}) => (
  <Stack
    height={{ sx: 24, md: 48 }}
    minHeight={{ sx: 24, md: 48 }}
    alignItems="center"
    divider={<Divider orientation="vertical" flexItem />}
    direction="row"
    gap={1}
    width="100%"
  >
    <Typography
      textAlign="center"
      flex={1}
      variant="body2"
      color="text.secondary"
    >
      {label}
    </Typography>
    <Typography textAlign="center" flex={1} variant="body2" color="text.secondary" fontWeight="700">
      {value}
    </Typography>
  </Stack>
);

export const VoteConfirmationDialog = ({
  open, onClose, projectCandidate, amount,
}: VoteConfirmationDialogProps) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { data: election }: UseQueryResult<Election> = useCurrentElection();
  const { data: contractsInfo } = usePoolContracts(election
    ? election.projectCandidates.map((p) => p.contractAddress as Address)
    : []);

  if (!election || !contractsInfo) {
    return null;
  }

  const projectIndex = election?.projectCandidates.findIndex((p) => p.id === projectCandidate.id);
  const contractInfo = contractsInfo && contractsInfo[projectCandidate.contractAddress];

  return contractInfo && (
    <Dialog open={open} onClose={onClose} maxWidth="xl">
      <Stack direction="row">
        <Box width={440} display="flex" flexDirection="column">
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            {election && contractsInfo && (
              <Box display="flex" mt={2}>
                <PieChart
                  height={220}
                  width={290}
                  colors={palette}
                  margin={{ right: 5 }}
                  slotProps={{
                    legend: {
                      hidden: true,
                    },
                  }}
                  series={[
                    {
                      data: election.projectCandidates.map((currentProjectCandidate) => ({
                        id: currentProjectCandidate.id,
                        value: contractsInfo[currentProjectCandidate.contractAddress].percentage,
                        label: currentProjectCandidate.project.name[i18n.language as 'en' | 'fr'],
                      })),
                      valueFormatter: (item) => `${item.value.toFixed(2)}%`,
                      highlightScope: { faded: 'global', highlighted: 'item' },
                      cornerRadius: 5,
                      innerRadius: 20,
                      outerRadius: 100,
                    },
                  ]}
                />
              </Box>
            )}

            <Typography variant="h6" textAlign="center" mr={2} ml={2} mt={2} mb={2} flex={1}>
              <Trans
                i18nKey="label.home.vote-alert.text"
                values={{ name: projectCandidate.project.name[i18n.language as 'en' | 'fr'] }}
              >
                your-vote-for
                <Typography
                  variant="h6"
                  component="span"
                  color={palette[projectIndex]}
                >
                  name
                </Typography>
                has-been-registered
              </Trans>
            </Typography>
            <Divider />
            <InfoRow
              label={t('label.home.vote-alert.amount')}
              value={formatAmount(Number(amount))}
            />
            <Divider />
            <InfoRow
              label={t('label.home.vote-alert.lockup')}
              value={contractInfo.lockupPeriod?.toLocaleDateString(
                i18n.language,
                { day: 'numeric', month: 'long', year: 'numeric' },
              )}
            />
            <Divider />
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button variant="outlined" onClick={() => navigate('/claims')} autoFocus>
              {t('label.home.vote-alert.voting-profile')}
            </Button>
          </DialogActions>
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
          <Divider orientation="vertical" />
        </Box>
        <Box sx={{ display: { xs: 'none', md: 'flex' } }} width={350} display="flex" flexDirection="column">
          <DialogContent sx={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
            <Box display="flex" mt={2} height={220} alignItems="center" justifyContent="center">
              <img alt="mintera" width="80%" src="/images/mintera.png" />
            </Box>
            <Typography variant="h6" textAlign="center" mr={3} ml={3} mt={2} mb={2} flex={1}>
              {t('label.home.vote-alert.invest')}
            </Typography>
            <Divider />
            <InfoRow
              label={t('label.home.vote-alert.token-staked')}
              value={t('label.home.vote-alert.token-staked-value')}
            />
            <Divider />
            <InfoRow
              label={t('label.home.vote-alert.iapr')}
              value={(
                <Box>
                  12%
                  <Typography component="span" variant="body2" fontWeight="700" color="primary"> + 0.6%</Typography>
                </Box>
              )}
            />
            <Divider />
          </DialogContent>
          <DialogActions sx={{ justifyContent: 'center' }}>
            <Button
              variant="outlined"
              onClick={() => window.open('https://app.mintera.co/buy-mnte?referralCode=MINTERADEPIN01', '_blank')}
              autoFocus
            >
              {t('label.home.vote-alert.subscribe')}
            </Button>
          </DialogActions>
        </Box>
      </Stack>
    </Dialog>
  );
};
