import { Address } from 'abitype';
import { erc20Abi as abi } from 'viem';
import { useAccount, useReadContract } from 'wagmi';
import { TOKEN_CONTRACT } from '../config/token-contract.ts';
import { useWriteContractAndStoreTransaction } from './helpers.ts';

const tokenAddress = TOKEN_CONTRACT[import.meta.env.VITE_CHAIN_ID];

export const useReadBalance = () => {
  const account = useAccount();

  return useReadContract({
    abi,
    address: tokenAddress,
    functionName: 'balanceOf',
    args: [account.address as Address],
    query: { enabled: Boolean(account.address), select: (num) => Number(num) },
  });
};

export const useReadAllowance = ({ target }: { target: Address }) => {
  const account = useAccount();
  return useReadContract({
    abi,
    address: tokenAddress,
    functionName: 'allowance',
    args: [account.address as Address, target],
    query: { enabled: Boolean(account.address) },
  });
};

export const useReadSymbol = () => useReadContract({
  abi,
  address: tokenAddress,
  functionName: 'symbol',
});

export const useWriteApprove = () => {
  const { writeContractAsync } = useWriteContractAndStoreTransaction('APPROVE');
  return (
    { target, amount }: { target: Address; amount: bigint },
  ) => writeContractAsync(
    {
      abi,
      address: tokenAddress,
      functionName: 'approve',
      args: [target, amount],
    },
  );
};
