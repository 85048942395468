import { observer } from 'mobx-react-lite';
import {
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Chip,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Button from '@mui/material/Button';
import { Trans, useTranslation } from 'react-i18next';
import { useAccount } from 'wagmi';
import { ChevronRight, InfoOutlined } from '@mui/icons-material';
import { ProjectCandidate } from '../types/elections-store.ts';
import { ContractInfo } from '../types/projects-store.ts';
import { Connect } from './Connect.tsx';
import { formatGivebackAmount } from '../utils.tsx';
import { IAPR_TARGET, SDG } from '../constants.ts';
import { theme } from '../config/mui.ts';
import { CustomTooltip } from './CustomTooltip.tsx';

type ProjectCardProps = {
  projectCandidate: ProjectCandidate;
  contractInfo?: ContractInfo;
  canVote?: boolean;
  onSwitchProject: () => void;
};

export const ProjectCard = observer((
  {
    projectCandidate,
    contractInfo,
    canVote,
    onSwitchProject,
  }: ProjectCardProps,
) => {
  // const { projectsStore, tokenStore } = useStore();
  const { t, i18n } = useTranslation();
  const { project } = projectCandidate;
  const largeScreen = useMediaQuery('(min-width:900px)');
  const { status: accountStatus } = useAccount();
  const size = (largeScreen ? 'medium' : 'small') as 'medium' | 'small';
  return (
    <Card sx={{
      maxWidth: '100%', height: 460, minHeight: 480, maxHeight: 480, borderRadius: 4,
    }}
    >
      <Stack height="100%" width="100%" position="relative">
        <CardMedia
          sx={{ height: 200, cursor: 'pointer' }}
          onClick={() => onSwitchProject()}
          component="img"
          image={project.picture?.assetPublicURL}
          alt={project.name[i18n.language as 'en' | 'fr']}
        />
        <Stack direction="row" flexWrap="wrap" gap={1} position="absolute" m={1}>
          {project.projectTags?.map((tag) => (
            <Chip sx={{ backgroundColor: SDG[tag.slug] ? SDG[tag.slug].color : 'white', color: 'black' }} size={size} key={tag.id} label={tag.name[i18n.language as 'en' | 'fr']} />
          ))}
          <Chip sx={{ backgroundColor: theme.palette.background.default, border: 1 }} size={size} label={`${formatGivebackAmount(project.givebackAmountTarget)} $`} />
        </Stack>

        <CardContent sx={{
          overflow: 'auto', pt: 2, pl: 2, pr: 2, pb: 0, flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'stretch',
        }}
        >
          <Box
            sx={{
              overflow: 'hidden', display: 'flex', alignItems: 'center', cursor: 'pointer', mb: 2,
            }}
            onClick={() => onSwitchProject()}
          >
            <Typography variant="h6" align="center" flex={1} noWrap sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{project.name[i18n.language as 'en' | 'fr']}</Typography>
          </Box>
          <Box sx={{ flex: 1, overflow: 'hidden' }}>
            <Typography
              color="text.secondary"
              align="center"
              sx={{
                display: '-webkit-box',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                '-webkit-line-clamp': '3',
                '-webkit-box-orient': 'vertical',
              }}
            >
              {(project.shortDescription || project.description)[i18n.language as 'en' | 'fr']}
            </Typography>
          </Box>

          <Box sx={{ width: '100%' }}>
            <Divider />
            <Stack divider={<Divider />} width="100%">
              <Stack height={32} alignItems="center" divider={<Divider orientation="vertical" flexItem />} direction="row" gap={1} width="100%">
                <Typography textAlign="center" flex={1} variant="body2" color="text.secondary">{t('label.home.selected-project-card.lockup')}</Typography>
                {contractInfo?.lockupPeriod ? (
                  <Typography textAlign="center" flex={1} variant="body2" color="text.secondary" fontWeight="700">
                    {contractInfo.lockupPeriod?.toLocaleDateString(
                      i18n.language,
                      { day: 'numeric', month: 'long', year: 'numeric' },
                    )}
                  </Typography>
                ) : (
                  <Typography textAlign="center" flex={1} variant="body2" color="text.secondary" fontWeight="700">
                    ...
                  </Typography>
                )}
              </Stack>
              <Stack
                height={32}
                alignItems="center"
                divider={<Divider orientation="vertical" flexItem />}
                direction="row"
                gap={1}
                width="100%"
              >
                <Box color="primary.main" component="span" display="flex" alignItems="center" justifyContent="center" gap={1} flex={1}>
                  <Typography
                    textAlign="center"
                    variant="body2"
                    fontWeight="700"
                  >
                    {t('label.home.project-card.iapr')}
                  </Typography>
                  <CustomTooltip title={(
                    <Trans
                      i18nKey="label.home.project-card.iapr-tooltip"
                      values={{
                        apr: contractInfo ? contractInfo.fixedAPY : '...',
                        iapr: projectCandidate.iapr / 100,
                        total: contractInfo ? contractInfo.fixedAPY + (projectCandidate.iapr / 100) : '...',
                        target: IAPR_TARGET.name,
                        link: IAPR_TARGET.link,
                      }}
                    >
                      with-iapr
                      <a href={`https://${IAPR_TARGET.link}`} target="_blank" rel="noreferrer">link</a>
                    </Trans>
                  )}
                  >
                    <InfoOutlined color="inherit" sx={{ fontSize: '1em' }} />
                  </CustomTooltip>
                </Box>
                <Box flex={1} textAlign="center">
                  {contractInfo?.fixedAPY ? (
                    <>
                      <Typography component="span" variant="body2" color="text.secondary" fontWeight="700">
                        {`${contractInfo.fixedAPY}%`}
                      </Typography>
                      <Typography
                        component="span"
                        variant="body2"
                        fontWeight="700"
                        color="primary.main"
                      >
                        {` + ${projectCandidate.iapr / 100}%`}
                      </Typography>
                    </>
                  ) : (
                    <Typography textAlign="center" flex={1} variant="body2" color="text.secondary" fontWeight="700">
                      ...
                    </Typography>
                  )}
                </Box>
              </Stack>
            </Stack>
            <Divider />
          </Box>
        </CardContent>
        <CardActions sx={{ justifyContent: 'center', p: 2 }}>
          <Connect connectOnly />
          {accountStatus !== 'disconnected' && (
            <Button variant="outlined" size="medium" onClick={() => onSwitchProject()} sx={{ opacity: (typeof canVote === 'undefined') ? 0 : 1 }} endIcon={<ChevronRight />}>
              {canVote ? t('label.home.project-card.vote') : t('label.home.project-card.learn-more')}
            </Button>
          )}
        </CardActions>
      </Stack>
    </Card>
  );
});
