import axios from 'axios';
import { PROJECT_BASE_URL } from '../constants.ts';

const PROJECT_CONFIG = {
  baseURL: PROJECT_BASE_URL,
  headers: {
    Accept: 'application/json;version=0.0',
    'Content-Type': 'application/json;',
  },

};

export const projectApi = axios.create({ ...PROJECT_CONFIG });
