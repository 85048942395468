import { RouteObject } from 'react-router';
import { BuyTreebs } from './BuyTreebs.tsx';
import { ErrorBoundary } from './ErrorBoundary.tsx';
import { Home } from './Home.tsx';
import { Login } from './Login.tsx';
import { Claims } from './Claims.tsx';
import { Results } from './Results.tsx';
import { HomeSelect } from './HomeSelect.tsx';
import { HomeViewProject } from './HomeViewProject.tsx';
import { Wip } from './WIP.tsx';

export const routes: RouteObject[] = [
  {
    path: '/',
    errorElement: <ErrorBoundary />,
    element: <Home />,
    children: [
      {
        path: '',
        element: <HomeSelect />,
      },
      {
        path: '/project/:projectId',
        element: <HomeViewProject />,
      },
    ],
  },
  {
    path: '/buyTreebs',
    errorElement: <ErrorBoundary />,
    element: <BuyTreebs />,
  },
  {
    path: '/login',
    errorElement: <ErrorBoundary />,
    element: <Login />,
  },
  {
    path: '/claims',
    errorElement: <ErrorBoundary />,
    element: <Claims />,
  },
  {
    path: '/results',
    errorElement: <ErrorBoundary />,
    element: <Results />,
  },
  {
    path: '/wip',
    errorElement: <ErrorBoundary />,
    element: <Wip />,
  },
];
