import { useAccount } from 'wagmi';
import { Navigate } from 'react-router';
import { Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import { useTranslation } from 'react-i18next';
import { Address } from 'abitype';
import { config } from '../config/wagmi.ts';
import { useReadBalance } from '../contracts/token-service.ts';
import { Layout } from '../components/Layout.tsx';
import { formatAmount } from '../utils.tsx';
import { usePoolContracts } from '../contracts/pool-service.ts';
import { useCurrentElection } from '../api/useCurrentElection.tsx';

export const Results = observer(() => {
  const { status: accountStatus, chain, chainId } = useAccount();
  const { data: userBalance } = useReadBalance();
  const { i18n } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      width: 150,
      flex: 1,
      editable: false,
    },
    {
      field: 'address',
      headerName: 'Address',
      width: 150,
      flex: 1,
      editable: false,
      renderCell: (params) => (
        <a href={`${chain?.blockExplorers?.default?.url}/address/${params.value}`}>{params.value}</a>
      ),
    },
    {
      field: 'amountStaked',
      headerName: 'Your votes',
      width: 150,
      editable: false,
      type: 'number',
    },
    {
      field: 'totalStaked',
      headerName: 'Total staked',
      width: 150,
      editable: false,
      type: 'number',
    },
    {
      field: 'result',
      headerName: 'Result',
      type: 'number',
      width: 110,
      editable: false,
      renderCell: (params) => (
        <span>
          {params.value}
          {' '}
          %
        </span>
      ),
    },
  ];

  const { data: election } = useCurrentElection();

  const { data: contractsInfo } = usePoolContracts(election
    ? election.projectCandidates.map((p) => p.contractAddress as Address)
    : []);

  const projectCandidates = election && contractsInfo
    && [
      ...election.projectCandidates
        .filter((projectCandidate) => contractsInfo[projectCandidate.contractAddress]),
    ].sort((a, b) => (contractsInfo[b.contractAddress].percentage)
      - (contractsInfo[a.contractAddress].percentage));

  const rows = projectCandidates && contractsInfo && projectCandidates.map((projectCandidate) => ({
    id: projectCandidate.id,
    name: projectCandidate.project.name[i18n.language as 'en' | 'fr'],
    address: projectCandidate.contractAddress,
    amountStaked: formatAmount(contractsInfo[projectCandidate.contractAddress].amountStaked),
    totalStaked: formatAmount(contractsInfo[projectCandidate.contractAddress].totalDeposited),
    result: Math.round(contractsInfo[projectCandidate.contractAddress].percentage * 100) / 100,
  }));

  if (accountStatus !== 'connected' || chainId !== config.chains[0].id) {
    return <Navigate to="/" replace />;
  }

  if (Number(userBalance) / 1e18 <= 2000) {
    return <Navigate to="/buyTreebs" replace />;
  }

  return rows && (
    <Layout>
      <Typography variant="h3">Results</Typography>
      <DataGrid
        rows={rows}
        columns={columns}
        slots={{ toolbar: GridToolbar }}
        hideFooter
      />
    </Layout>
  );
});
