import { AMOUNT_MULTIPLICATOR_COEF_100_000, DEFAULT_DECIMAL_NB } from './constants.ts';

export const formatAmount = (value: number | undefined) => {
  if (value === undefined) return '...';

  const dec = Math.round((value / 1e16) % 100);
  const units = Math.round(value / 1e18);
  return `${units}.${dec.toString().padStart(2, '0')}`;
};

export function formatGivebackAmount(
  rawAmount: number,
  multiplicator: number = AMOUNT_MULTIPLICATOR_COEF_100_000,
  maximumFractionDigits: number = 0,
): string {
  const minimumFractionDigits = Math.min(DEFAULT_DECIMAL_NB, maximumFractionDigits);

  return (rawAmount / multiplicator)
    .toLocaleString('fr', {
      maximumFractionDigits,
      minimumFractionDigits,
    });
}
