import {
  Stack, Toolbar, Typography, useMediaQuery,
} from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { theme } from '../config/mui.ts';

export const Footer = observer(() => {
  const { t } = useTranslation();

  const largeScreen = useMediaQuery('(min-width:900px)');
  const maxItems = largeScreen ? 4 : 3;
  const navItems = [
    { key: '1', label: t('label.footer.terms'), href: 'https://www.retreeb.io/tos-impact-launchpad' },
    { key: '2', label: t('label.footer.privacy'), href: 'https://www.retreeb.io/privacy-policy' },
    { key: '3', label: t('label.footer.cookies'), href: 'https://www.retreeb.io/cookie-policy' },
    {
      key: '4',
      render: () => (
        <Typography
          variant="body1"
          sx={{
            '& a': { color: theme.palette.text.primary },
          }}
        >
          <Trans i18nKey="label.footer.made">
            1
            <a href="https://www.retreeb.io/" target="_blank" rel="noreferrer">2</a>
            3
          </Trans>
        </Typography>
      ),
    },
  ].filter((_, i) => i < maxItems);

  return (
    <Toolbar disableGutters variant="regular">
      <Stack mr={4} direction="row" gap={{ xs: 2, md: 5 }} alignItems="center" width="100%" justifyContent="center">
        {navItems.map((item) => (
          <div key={item.key}>
            { item.render && item.render() }
            { item.label && item.href && (
            <Button
              sx={{ ...theme.typography.body1, fontSize: { xs: 12, md: 16 } }}
              onClick={() => window.open(item.href, '_blank')}
              color="inherit"
            >
              {item.label}
            </Button>
            )}
          </div>
        ))}
      </Stack>
    </Toolbar>
  );
});
