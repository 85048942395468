import { observer } from 'mobx-react-lite';
import { getTransactionConfirmations } from '@wagmi/core';
import { useEffect } from 'react';
import { Address } from 'abitype';
import { config } from '../config/wagmi.ts';
import { useStore } from '../stores/store-context.tsx';

type TransactionProps = {
  onUpdate: () => void
}

export const Transactions = observer(({ onUpdate }: TransactionProps) => {
  const { transactionsStore } = useStore();
  const activeTransaction = transactionsStore.activeTransactions;

  // const [currentTransaction, setCurrentTransaction] = useState<TransactionSnapshot>();
  // const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const t = setInterval(() => {
      activeTransaction.forEach((transaction) => {
        if (transaction.hash && transaction.status === 'success') {
          getTransactionConfirmations(config, { hash: transaction.hash as Address })
            .then((confirmations) => {
              if (confirmations > 0) {
                transactionsStore.setTransactionStatus(transaction.id, 'confirmed');
                onUpdate();
              }
            });
        }
      });
    }, 2000);

    return () => {
      clearInterval(t);
    };
  }, [transactionsStore, activeTransaction, onUpdate]);

  // const lastTransaction = activeTransaction[0];
  // useEffect(() => {
  //   if (lastTransaction) {
  //     setCurrentTransaction(lastTransaction);
  //     setIsVisible(true);
  //   }
  // }, [lastTransaction]);

  return false;
  //
  // return currentTransaction && (
  //   <Snackbar
  //     open={isVisible}
  //     key={currentTransaction.id}
  //     autoHideDuration={currentTransaction.status === 'confirmed' ? 1000 : null}
  //     onClose={() => {
  //       setIsVisible(false);
  //     }}
  //   >
  //     <Alert
  //       severity={currentTransaction.status === 'pending'
  //       ? 'warning' : currentTransaction.status === 'error'
  //       ? 'error' : 'success'}
  //       variant="filled"
  //       sx={{ width: '100%' }}
  //     >
  //       {currentTransaction.id}
  //       {' '}
  //       {currentTransaction.type}
  //       {' '}
  //       {currentTransaction.status}
  //     </Alert>
  //   </Snackbar>
  // );
});
