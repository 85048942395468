import { Box, Stack } from '@mui/material';
import { Navigate } from 'react-router';
import { useAccount } from 'wagmi';
import { observer } from 'mobx-react-lite';
import { useQuery } from '@tanstack/react-query';
import { Address } from 'abitype';
import { config } from '../config/wagmi.ts';
import { Layout } from '../components/Layout.tsx';
import { Election } from '../types/elections-store.ts';
import { usePoolContracts } from '../contracts/pool-service.ts';
import { ClaimTableSection } from '../components/ClaimTableSection.tsx';
import { HistorySection } from '../components/HistorySection.tsx';
import { Transactions } from '../components/Transactions.tsx';
import { projectApi } from '../api/request.ts';

export const Claims = observer(() => {
  const { status: accountStatus, chainId } = useAccount();

  const { data: elections } = useQuery<Election[]>({
    queryKey: ['elections'],
    queryFn: () => projectApi.get<Election[]>('/elections').then((res) => res.data),
  });

  const now = new Date().getTime();

  const pastElections = elections?.filter((e) => (now > e.endDate));
  const currentElection = elections?.find((e) => (e.startDate < now) && (now < e.endDate));

  const { data: contractsInfo, refetch: refetchPool } = usePoolContracts(elections
    ? elections
      ?.flatMap((election) => election.projectCandidates
        .map((p) => p.contractAddress as Address))
    : []);

  if ((accountStatus !== 'connected' && accountStatus !== 'reconnecting')
    || chainId !== config?.chains[0]?.id) {
    return <Navigate to="/" replace />;
  }

  const pastProjects = pastElections
    && contractsInfo
    && pastElections
      .flatMap((election) => election.projectCandidates);

  return (
    <Layout overflow="auto">
      <Transactions onUpdate={refetchPool} />
      <Stack gap={2}>
        <Box>
          {currentElection && contractsInfo
            && (
            <ClaimTableSection
              type="current"
              projectCandidates={currentElection.projectCandidates}
              contractsInfo={contractsInfo}
            />
            )}
        </Box>
        <Box>
          {pastProjects && contractsInfo
            && (
            <ClaimTableSection
              type="past"
              projectCandidates={pastProjects}
              contractsInfo={contractsInfo}
            />
            )}
        </Box>

        <Box>
          {pastElections && contractsInfo
            && (
            <HistorySection
              pastElections={pastElections}
              contractsInfo={contractsInfo}
            />
            )}
        </Box>
      </Stack>
    </Layout>
  );
});
