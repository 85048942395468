import React, { PropsWithChildren, useEffect, useRef } from 'react';
import { Box, Toolbar } from '@mui/material';
import { useLocation } from 'react-router';
import { Header } from './Header.tsx';
import { Footer } from './Footer.tsx';
import { theme } from '../config/mui.ts';

type LayoutProps = {
  overflow?:string
  header?: React.ComponentType
}

export const Layout = (
  {
    children,
    header: CustomHeader, overflow,
  }: PropsWithChildren<LayoutProps>,
) => {
  const { pathname } = useLocation();
  const boxRef = useRef<HTMLDivElement>();
  useEffect(() => {
    if (boxRef.current) {
      boxRef.current.scrollTo(0, 0);
    }
  }, [pathname]);

  return (
    <Box
      sx={{ background: theme.palette.background.default, color: theme.palette.text.primary }}
      display="flex"
      flexDirection="column"
      height="100vh"
      overflow="hidden"
    >
      {CustomHeader ? <CustomHeader /> : <Header />}
      <Box display="flex" flexDirection="column" overflow="hidden" flex={1}>
        <Toolbar />
        <Box
          ref={boxRef}
          flex={1}
          pt={4}
          display="flex"
          flexDirection="column"
          overflow={overflow || 'hidden'}
        >
          <Box
            flex={1}
            pl={{ xs: 1, sm: 2, lg: 12 }}
            pr={{ xs: 1, sm: 2, lg: 12 }}
            display="flex"
            flexDirection="column"
          >
            {children}
          </Box>
          <Footer />
        </Box>
      </Box>
    </Box>
  );
};
