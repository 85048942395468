import { MutateOptions } from '@tanstack/react-query';
import { Config, writeContract, WriteContractErrorType } from '@wagmi/core';
import type { WriteContractData, WriteContractVariables } from '@wagmi/core/query';
import type { Abi, ContractFunctionArgs, ContractFunctionName } from 'viem';
import { ResolvedRegister, useConfig } from 'wagmi';
import { useStore } from '../stores/store-context.tsx';

export type ContractMutateOptions = MutateOptions<
  WriteContractData,
  WriteContractErrorType,
  WriteContractVariables<
    Abi,
    string,
    readonly unknown[],
    ResolvedRegister['config'],
    ResolvedRegister['config']['chains'][number]['id']
  >,
  unknown
>;

export const useWriteContractAndStoreTransaction = <config extends Config>(
  type: string,
) => {
  const cfg = useConfig<config>();
  const { transactionsStore } = useStore();

  const writeContractAsync = async <
      const abi extends Abi | readonly unknown[],
      functionName extends ContractFunctionName<abi, 'nonpayable' | 'payable'>,
      args extends ContractFunctionArgs<
        abi,
        'nonpayable' | 'payable',
        functionName
      >,
      chainId extends config['chains'][number]['id'],
    >(parameters: WriteContractVariables<abi, functionName, args, config, chainId>) => {
    const id = crypto.randomUUID();
    transactionsStore.addTransaction({ id, status: 'idle', type });
    try {
      const hash = await writeContract(cfg, parameters);
      transactionsStore.setTransactionStatus(id, 'success');
      transactionsStore.setTransactionHash(id, hash);
      return hash;
    } catch (e) {
      transactionsStore.setTransactionStatus(id, 'error');
      throw e;
    }
  };

  return {
    writeContractAsync,
  };
};
