import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { DataGrid, gridClasses, GridColDef } from '@mui/x-data-grid';
import {
  AccordionDetails,
  Avatar,
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Stack,
  Typography,
  useMediaQuery,
} from '@mui/material';
import Button from '@mui/material/Button';
import { Address } from 'abitype';
import { BaseError } from 'wagmi';
import { formatAmount } from '../utils.tsx';
import { StyledAccordion } from './StyledAccordion.tsx';
import { StyledAccordionSummary } from './StyledAccordionSummary.tsx';
import { ProjectCandidate } from '../types/elections-store.ts';
import { ContractInfo } from '../types/projects-store.ts';
import { useWriteClaimRewards, useWriteWithdrawAll } from '../contracts/pool-service.ts';
import { TransactionsStore } from '../stores/transactions-store.ts';
import { useStore } from '../stores/store-context.tsx';
import { ClaimedRewardsConfirmationDialog } from './ClaimedRewardsConfirmationDialog.tsx';
import { useSnackbar } from './SnackbarProvider.tsx';

const waitForTransactionsApproval = async (
  transactionsStore: TransactionsStore,
  hashes: Address[],
) => {
  while (!hashes.reduce((r, hash) => r && transactionsStore.getTransactionByHash(hash)?.status === 'confirmed', true)) {
    // eslint-disable-next-line no-await-in-loop
    await new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
  }
};

type ClaimRewardsProps = {
  type: 'current' | 'past'
  projectCandidates: ProjectCandidate[]
  contractsInfo: { [key: Address]: ContractInfo }
}

export const ClaimTableSection = (
  {
    type,
    projectCandidates,
    contractsInfo,
  }: ClaimRewardsProps,
) => {
  const { t, i18n } = useTranslation();
  const [selectedItems, setSelectedItems] = useState<string[]>([]);
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>();
  const [dialogOpen, setDialogOpen] = useState({ open: false, claimed: 0 });
  const setSnackbar = useSnackbar();
  const claimRewards = useWriteClaimRewards();
  const withdrawAll = useWriteWithdrawAll();
  const { transactionsStore } = useStore();
  const largeScreen = useMediaQuery('(min-width:900px)');

  const doClaimRewards = async () => {
    try {
      const selectedCandidates = projectCandidates
        .filter((projectCandidate) => selectedItems.includes(projectCandidate.id));

      const addresses = selectedCandidates
        .map((pc) => pc.contractAddress);
      setLoadingMessage(t(`label.claims.${type}.claim.pending`));
      const claimCall = type === 'current' ? claimRewards : withdrawAll;
      const hashes = await Promise.all(addresses.map((address) => claimCall({
        address,
      })));
      setLoadingMessage(t('label.transactions.waiting-confirmation'));
      await waitForTransactionsApproval(transactionsStore, hashes);
      setSelectedItems([]);

      const claimed = selectedCandidates.reduce((acc, candidate) => acc
        + Number(contractsInfo[candidate.contractAddress].rewardOf), 0);

      setDialogOpen({ open: true, claimed });
    } catch (e) {
      const baseError = e as BaseError;
      if (baseError.shortMessage) {
        setSnackbar(baseError.shortMessage);
      }
    } finally {
      setLoadingMessage(undefined);
    }
  };

  const nameColumn:GridColDef = {
    field: 'name',
    headerName: t('label.claims.name'),
    renderCell: (params) => (
      <Stack direction="row" gap={1} alignItems="center" overflow="hidden">
        <Avatar src={params.row.projectCandidate.project.picture?.assetPublicURL} />
        <Typography overflow="hidden" textOverflow="ellipsis">{params.value}</Typography>
      </Stack>
    ),
    flex: 2,
  };
  const lockupColumn:GridColDef = { field: 'lockup', headerName: t('label.claims.lockup'), flex: 1 };
  const amountColumn:GridColDef = {
    field: 'amount', headerName: t('label.claims.amount'), flex: 1, type: 'number',
  };
  const rewardsColumn: GridColDef = {
    field: 'rewards', headerName: t('label.claims.rewards'), flex: 1, type: 'number',
  };
  const percentageColumn: GridColDef = {
    field: 'percentage',
    headerName: t('label.claims.percentage'),
    flex: 1,
    type: 'number',
    renderCell: (params) => (
      <span>
        {params.value}
        %
      </span>
    ),
  };

  const columns: GridColDef[] = largeScreen ? [
    nameColumn,
    lockupColumn,
    amountColumn,
    rewardsColumn,
    percentageColumn,
  ] : [
    nameColumn,
    amountColumn,
    rewardsColumn,
  ];

  const tableData = projectCandidates
    && contractsInfo
    && projectCandidates
      .filter((projectCandidate) => (
        (contractsInfo[projectCandidate.contractAddress].amountStaked || 0) > 0
        || (contractsInfo[projectCandidate.contractAddress].rewardOf || 0) > 0))
      .map((candidate) => ({
        projectCandidate: candidate,
        id: candidate.id,
        name: candidate.project.name[i18n.language as 'en' | 'fr'],
        lockup: contractsInfo[candidate.contractAddress].lockupPeriod.toLocaleDateString(),
        amount: formatAmount(contractsInfo[candidate.contractAddress].amountStaked),
        rewards: formatAmount(contractsInfo[candidate.contractAddress].rewardOf),
        percentage:
          Math.round(contractsInfo[candidate.contractAddress].percentage * 100) / 100,
      }));

  return (
    <StyledAccordion defaultExpanded sx={{ backgroundColor: 'transparent' }}>
      <StyledAccordionSummary aria-controls="panel1d-content" id="panel1d-header">
        <Typography variant="h6">
          {' '}
          {t(`label.claims.${type}.title`)}
        </Typography>
        <Box flex={1} />
      </StyledAccordionSummary>
      <Button
        sx={{ display: { xs: 'none', md: 'block' } }}
        variant="contained"
        disabled={selectedItems.length === 0}
        onClick={(e) => {
          doClaimRewards();
          e.stopPropagation();
        }}
      >
        {t(selectedItems.length === 0 ? `label.claims.${type}.claim.select` : `label.claims.${type}.claim.title`)}
      </Button>
      <AccordionDetails>
        {tableData && tableData.length > 0 && (
          <>
            <DataGrid
              sx={{
                border: 0,
                [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                  outline: 'none',
                },
                [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
                  outline: 'none',
                },
              }}
              disableColumnMenu
              disableColumnFilter
              disableColumnSelector
              rows={tableData}
              columns={columns}
              hideFooter
              checkboxSelection
              rowSelectionModel={selectedItems}
              onRowSelectionModelChange={(items) => {
                setSelectedItems(items.map((i) => i.toString()));
              }}
            />
            <Button
              sx={{ display: { xs: 'block', md: 'none' }, mt: 1 }}
              fullWidth
              variant="contained"
              disabled={selectedItems.length === 0}
              onClick={(e) => {
                doClaimRewards();
                e.stopPropagation();
              }}
            >
              {t(selectedItems.length === 0 ? `label.claims.${type}.claim.select` : `label.claims.${type}.claim.title`)}
            </Button>
          </>
        )}

        {tableData && tableData.length === 0 && (
          <Stack gap={2}>
            <Divider />
            <Typography variant="subtitle2" textAlign="center">
              {t('label.claims.empty')}
            </Typography>
            <Divider />
          </Stack>
        )}
        <Backdrop
          sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={Boolean(loadingMessage)}
        >
          <Stack alignItems="center">
            <CircularProgress color="inherit" title="hello" />
            <Typography>{loadingMessage}</Typography>
          </Stack>
        </Backdrop>
        <ClaimedRewardsConfirmationDialog
          open={dialogOpen.open}
          amount={dialogOpen.claimed}
          onClose={() => setDialogOpen({ open: false, claimed: 0 })}
        />
      </AccordionDetails>
    </StyledAccordion>
  );
};
