import { useTranslation } from 'react-i18next';
import {
  AccordionDetails, Divider, Stack, Typography,
} from '@mui/material';
import { DataGrid, gridClasses, GridColDef } from '@mui/x-data-grid';
import { Address } from 'abitype';
import clsx from 'clsx';
import { StyledAccordion } from './StyledAccordion.tsx';
import { StyledAccordionSummary } from './StyledAccordionSummary.tsx';
import { Election } from '../types/elections-store.ts';
import { ContractInfo } from '../types/projects-store.ts';

type HistorySectionProps = {
  pastElections: Election[]
  contractsInfo: { [key: Address]: ContractInfo }
}
export const HistorySection = ({ pastElections, contractsInfo }: HistorySectionProps) => {
  const { t, i18n } = useTranslation();

  const columns: GridColDef[] = [
    {
      field: 'voteNumber',
      headerName: t('label.claims.voteNumber'),
      flex: 1,
      sortable: false,
      renderCell: (params) => (
        params.row.indexInVote === 0 ? params.value : null
      ),
      cellClassName: (params) => clsx({
        'no-border': params.row.indexInVote !== params.row.winnersSeatsCount - 1,
        [`span-${params.row.winnersSeatsCount}-rows`]: params.row.indexInVote === 0,
      }),
    },
    {
      field: 'winners',
      headerName: t('label.claims.winners'),
      flex: 2,
      sortable: false,
    },
    {
      field: 'iapr',
      headerName: t('label.claims.iapr'),
      flex: 1,
      sortable: false,
      type: 'number',
      renderCell: (params) => (
        <span>
          {params.value}
          %
        </span>
      ),
    },
    {
      field: 'percentage',
      headerName: t('label.claims.percentage'),
      flex: 1,
      sortable: false,
      type: 'number',
      renderCell: (params) => (
        <span>
          {params.value}
          %
        </span>
      ),
    },
  ];

  const data = pastElections
    .flatMap((election) => [...election.projectCandidates]
      .sort((a, b) => (
        contractsInfo[b.contractAddress].percentage - contractsInfo[a.contractAddress].percentage
      ))
      .slice(0, election.winnersSeatsCount)
      .map((projectCandidate, idx) => ({
        id: projectCandidate.id,
        voteNumber: election.name[i18n.language as 'en' | 'fr'],
        winners: projectCandidate.project.name[i18n.language as 'en' | 'fr'],
        indexInVote: idx,
        winnersSeatsCount: election.winnersSeatsCount,
        iapr: projectCandidate.iapr / 100,
        percentage:
          Math.round(contractsInfo[projectCandidate.contractAddress].percentage * 100)
          / 100,
      })));

  return (
    <StyledAccordion defaultExpanded>
      <StyledAccordionSummary>
        <Typography variant="h6">
          {' '}
          {t('label.claims.history')}
        </Typography>
      </StyledAccordionSummary>
      <AccordionDetails>
        {data && data.length !== 0 && (
          <DataGrid
            rowHeight={52}
            sx={{
              border: 0,
              [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
                outline: 'none',
              },
              [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]: {
                outline: 'none',
              },
              '& .no-border': {
                borderBottom: 0,
              },
              '& .span-2-rows': {
                minHeight: '104px !important',
                maxHeight: '104px !important',
              },
              '& .span-3-rows': {
                minHeight: '156px !important',
                maxHeight: '156px !important',
              },
              '& .span-4-rows': {
                minHeight: '208px !important',
                maxHeight: '208px !important',
              },
            }}
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
            disableRowSelectionOnClick
            rows={data}
            columns={columns}
            hideFooter
          />
        )}
        {data && data.length === 0 && (
          <Stack gap={2}>
            <Divider />
            <Typography variant="subtitle2" textAlign="center">
              {t('label.claims.empty')}
            </Typography>
            <Divider />
          </Stack>
        )}
      </AccordionDetails>
    </StyledAccordion>
  );
};
