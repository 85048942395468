import { AppBar, Stack, Toolbar } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';

export const WipHeader = observer(() => {
  const { t } = useTranslation();

  return (
    <AppBar>
      <Toolbar>
        <Stack direction="row" gap={2} alignItems="center" justifyContent="start" flex={1}>
          <img src="/images/retreeb.svg" alt="logo" height={36} />
        </Stack>
        <Button variant="contained" color="primary" onClick={() => window.open('https://linktr.ee/Retreebbuy', '_blank')}>{t('label.header.buyTreeb')}</Button>
      </Toolbar>
    </AppBar>
  );
});
