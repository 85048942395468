import { useNavigate, useOutletContext, useParams } from 'react-router';
import { useEffect } from 'react';
import { ViewProject } from '../components/ViewProject.tsx';
import { HomeContext } from './Home.tsx';

export const HomeViewProject = () => {
  const { projectId } = useParams();
  const {
    election, contractsInfo, setActiveStep, canVote,
  } = useOutletContext<HomeContext>();
  const navigate = useNavigate();
  const selectedProjectCandidate = election.projectCandidates.find((p) => p.id === projectId);
  const contractInfo = selectedProjectCandidate
    && contractsInfo?.[selectedProjectCandidate.contractAddress];

  useEffect(() => {
    if (canVote && contractInfo && contractInfo.allowance > 0) {
      setActiveStep(2);
    } else {
      setActiveStep(1);
    }
  }, [contractInfo, setActiveStep, canVote]);

  useEffect(() => {
    if (!selectedProjectCandidate) {
      navigate('/');
    }
  }, [selectedProjectCandidate, navigate]);

  return selectedProjectCandidate && (
    <ViewProject
      projectCandidate={selectedProjectCandidate}
      canVote={canVote}
      contractsInfo={contractsInfo}
    />
  );
};
