import { Typography } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { Layout } from '../components/Layout.tsx';

export const BuyTreebs = observer(() => (
  <Layout>
    <Typography variant="h2">Buy Treebs</Typography>
    <Typography><a href="https://linktr.ee/Retreebbuy">here</a></Typography>
  </Layout>
));
