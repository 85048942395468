import { useTranslation } from 'react-i18next';
import {
  Dialog, DialogActions, DialogContent, Divider, Stack, Typography, useMediaQuery,
} from '@mui/material';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router';
import { formatAmount } from '../utils.tsx';

type ClaimedRewardsConfirmationDialogProps = {
  amount: number;
  open: boolean;
  onClose: () => void;
};

export const ClaimedRewardsConfirmationDialog = ({
  open, onClose, amount,
}: ClaimedRewardsConfirmationDialogProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const largeScreen = useMediaQuery('(min-width:900px)');

  return (
    <Dialog open={open} onClose={onClose} fullWidth>
      <DialogContent>
        <Typography variant="h6" textAlign="center" mr={3} ml={3} mt={2} mb={2}>
          {t('label.claims.claim-alert.text')}
        </Typography>
        <Divider />
        <Stack
          height={{ sx: 24, md: 48 }}
          alignItems="center"
          divider={<Divider orientation="vertical" flexItem />}
          direction="row"
          gap={1}
          width="100%"
        >
          <Typography
            textAlign="center"
            flex={1}
            variant="body2"
            color="text.secondary"
          >
            {t('label.claims.claim-alert.amount')}
          </Typography>
          <Typography textAlign="center" flex={1} variant="body2" color="text.secondary" fontWeight="700">
            {formatAmount(amount)}
          </Typography>
        </Stack>
        <Divider />
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => navigate('/')} autoFocus>
          {t(largeScreen ? 'label.claims.claim-alert.vote-again' : 'label.header.vote')}
        </Button>
        <Button variant="contained" onClick={onClose} autoFocus>
          {t(largeScreen ? 'label.claims.claim-alert.voting-profile' : 'label.header.my-voting-profile')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
