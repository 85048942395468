import { Address } from 'abitype';
import { observer } from 'mobx-react-lite';
import { Trans, useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import { ChangeEvent, useState } from 'react';
import {
  Backdrop,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  Divider,
  Stack,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import { InfoOutlined } from '@mui/icons-material';
import { BaseError, useAccount } from 'wagmi';
import { useWriteDeposit } from '../contracts/pool-service.ts';
import { useReadBalance, useWriteApprove } from '../contracts/token-service.ts';
import { useStore } from '../stores/store-context.tsx';
import { TransactionsStore } from '../stores/transactions-store.ts';
import { ProjectCandidate } from '../types/elections-store.ts';
import { ContractInfo } from '../types/projects-store.ts';
import { VoteConfirmationDialog } from './VoteConfirmationDialog.tsx';
import { useSnackbar } from './SnackbarProvider.tsx';
import { Connect } from './Connect.tsx';
import { IAPR_TARGET } from '../constants.ts';
import { CustomTooltip } from './CustomTooltip.tsx';

type SelectedProjectCardProps = {
  projectCandidate: ProjectCandidate;
  canVote?: boolean;
  contractsInfo?: {[key: Address]: ContractInfo};
};

const waitForTransactionApproval = async (transactionsStore: TransactionsStore, hash: Address) => {
  while (transactionsStore.getTransactionByHash(hash)?.status !== 'confirmed') {
    // eslint-disable-next-line no-await-in-loop
    await new Promise((resolve) => {
      setTimeout(resolve, 1000);
    });
  }
};

export const SelectedProjectCard = observer((
  {
    projectCandidate,
    canVote,
    contractsInfo,
  }: SelectedProjectCardProps,
) => {
  const { transactionsStore } = useStore();

  const { t, i18n } = useTranslation();

  const [amountToStake, setAmountToStake] = useState<number>(0);
  const [loadingMessage, setLoadingMessage] = useState<string | undefined>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const setSnackbar = useSnackbar();
  const { status: accountStatus } = useAccount();
  const contractInfo = contractsInfo && contractsInfo[projectCandidate.contractAddress as Address];

  const changeAmountToStake = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (contractInfo && value <= contractInfo.allowance / 1e18 && value >= 0) {
      setAmountToStake(value);
    }
  };

  const approve = useWriteApprove();
  const deposit = useWriteDeposit();

  const { data: balance } = useReadBalance();
  const doApprove = async (amount: bigint) => {
    try {
      setLoadingMessage(t('label.home.selected-project-card.approve-pending'));
      const approvalHash = await approve({
        target: projectCandidate.contractAddress as Address,
        amount,
      });
      setLoadingMessage(t('label.transactions.waiting-confirmation'));
      await waitForTransactionApproval(transactionsStore, approvalHash);
    } catch (e) {
      const baseError = e as BaseError;
      if (baseError.shortMessage) {
        setSnackbar(baseError.shortMessage);
      }
    } finally {
      setLoadingMessage(undefined);
    }
  };

  const doVote = async (amount: bigint) => {
    try {
      setLoadingMessage(t('label.home.selected-project-card.vote-pending'));
      const depositHash = await deposit({
        address: projectCandidate.contractAddress as Address,
        amount,
      });
      setLoadingMessage(t('label.transactions.waiting-confirmation'));
      await waitForTransactionApproval(transactionsStore, depositHash);
      setDialogOpen(true);
    } catch (e) {
      const baseError = e as BaseError;
      if (baseError.shortMessage) {
        setSnackbar(baseError.shortMessage);
      }
    } finally {
      setLoadingMessage(undefined);
    }
  };

  const amountApproved = (canVote && contractInfo && contractInfo.allowance > 0);
  const amount = BigInt(amountToStake * 1e18);
  const maxForVote = contractInfo
    ? Math.floor(Number(Math.min(balance || 0, contractInfo.allowance)) / 1e18)
    : 0;

  const total = contractsInfo
    ? Object.values(contractsInfo)
      .reduce((acc, current) => acc + current.totalDeposited, 0)
    : 0;

  const percentageAfterVote = contractInfo && Math.round(
    (contractInfo.totalDeposited + amountToStake * 1e18)
      / (total + amountToStake * 1e18)
      * 10000,
  ) / 100;

  return (
    <Card sx={{ flex: 1 }}>
      <CardHeader sx={{ textAlign: 'center' }} title={t('label.home.voteFor')} />
      <CardContent sx={{ p: 0 }}>
        <Divider />
        <Stack divider={<Divider />} width="100%">
          <Stack
            height={{ sx: 24, md: 48 }}
            alignItems="center"
            divider={<Divider orientation="vertical" flexItem />}
            direction="row"
            gap={1}
            width="100%"
          >
            <Box component="span" color="text.secondary" display="flex" alignItems="center" justifyContent="center" gap={1} flex={1}>
              <Typography
                textAlign="center"
                variant="body2"
              >
                {t('label.home.selected-project-card.lockup')}
              </Typography>
              <Tooltip title={t('label.home.selected-project-card.lockup-tooltip')}>
                <InfoOutlined color="inherit" sx={{ fontSize: '1em' }} />
              </Tooltip>
            </Box>
            <Typography textAlign="center" flex={1} variant="body2" color="text.secondary" fontWeight="700">
              {contractInfo?.lockupPeriod ? (
                <Typography textAlign="center" flex={1} variant="body2" color="text.secondary" fontWeight="700">
                  {contractInfo.lockupPeriod?.toLocaleDateString(
                    i18n.language,
                    { day: 'numeric', month: 'long', year: 'numeric' },
                  )}
                </Typography>
              ) : (
                <Typography textAlign="center" flex={1} variant="body2" color="text.secondary" fontWeight="700">
                  ...
                </Typography>
              )}
            </Typography>
          </Stack>
          <Stack
            height={{ sx: 24, md: 48 }}
            alignItems="center"
            divider={<Divider orientation="vertical" flexItem />}
            direction="row"
            gap={1}
            width="100%"
          >
            <Typography
              textAlign="center"
              flex={1}
              variant="body2"
              color="text.secondary"
            >
              {t('label.home.selected-project-card.apr')}
            </Typography>
            <Typography textAlign="center" flex={1} variant="body2" color="text.secondary" fontWeight="700">
              {contractInfo?.fixedAPY ? `${contractInfo?.fixedAPY}% ` : '...'}
            </Typography>
          </Stack>
          <Stack
            height={{ sx: 24, md: 48 }}
            alignItems="center"
            divider={<Divider orientation="vertical" flexItem />}
            direction="row"
            gap={1}
            width="100%"
          >
            <Box color="primary.main" component="span" display="flex" alignItems="center" justifyContent="center" gap={1} flex={1}>
              <Typography
                textAlign="center"
                variant="body2"
              >
                {t('label.home.selected-project-card.giveback')}
              </Typography>
              <CustomTooltip title={(
                <Trans
                  i18nKey="label.home.selected-project-card.giveback-tooltip"
                  values={{
                    apr: contractInfo ? contractInfo.fixedAPY : '...',
                    iapr: projectCandidate.iapr / 100,
                    total: contractInfo ? contractInfo.fixedAPY + (projectCandidate.iapr / 100) : '...',
                    target: IAPR_TARGET.name,
                    link: IAPR_TARGET.link,
                  }}
                >
                  with-iapr
                  <a href={`https://${IAPR_TARGET.link}`} target="_blank" rel="noreferrer">link</a>
                </Trans>
              )}
              >
                <InfoOutlined color="inherit" sx={{ fontSize: '1em' }} />
              </CustomTooltip>
            </Box>
            <Typography textAlign="center" flex={1} variant="body2" color="primary.main" fontWeight="700">
              {`${projectCandidate.iapr / 100}%`}
            </Typography>
          </Stack>
          <Stack
            height={{ sx: 24, md: 48 }}
            alignItems="center"
            divider={<Divider orientation="vertical" flexItem />}
            direction="row"
            gap={1}
            width="100%"
          >
            <Typography
              textAlign="center"
              flex={1}
              variant="body2"
              color="text.secondary"
            >
              {t('label.home.selected-project-card.result')}
            </Typography>
            <Typography textAlign="center" flex={1} variant="body2" color="text.secondary" fontWeight="700">
              {contractInfo ? (
                <>
                  {`${Math.round((contractInfo?.percentage || 0) * 100) / 100}%`}
                  {amountApproved && amountToStake > 0 && (
                    <Typography
                      sx={{ color: 'primary.main' }}
                      component="span"
                      textAlign="center"
                      flex={1}
                      variant="body2"
                      color="text.secondary"
                      fontWeight="700"
                    >
                      {` → ${percentageAfterVote}%`}
                    </Typography>
                  )}
                </>
              ) : '...'}
            </Typography>
          </Stack>
        </Stack>
        <Divider />
      </CardContent>
      <CardActions sx={{ padding: '16px' }}>
        <Stack direction="row" width="100%" justifyContent="center" gap={1}>
          {accountStatus === 'disconnected' && <Connect connectOnly />}
          {!canVote && accountStatus === 'connected' && (
            <Button
              variant="contained"
              onClick={() => {
                window.location.href = 'https://linktr.ee/Retreebbuy';
              }}
            >
              {t('label.home.buy-treebs')}
            </Button>
          )}
          {canVote && !amountApproved && (
            <Button
              onClick={() => {
                if (balance) {
                  doApprove(BigInt(balance));
                }
              }}
              color="primary"
              variant="contained"
            >
              {t('label.home.selected-project-card.approve')}
            </Button>
          )}
          {canVote && amountApproved && (
            <Stack direction="column" gap={1}>
              <Stack direction="row" width="100%" justifyContent="space-between" alignItems="center" gap={1} color="text.disabled">
                <Button
                  size="small"
                  color="inherit"
                  onClick={() => {
                    setAmountToStake(0);
                  }}
                >
                  0%
                </Button>
                <Button
                  sx={{ display: { xs: 'none', md: 'block' } }}
                  size="small"
                  color="inherit"
                  onClick={() => {
                    setAmountToStake(Math.floor(maxForVote / 4));
                  }}
                >
                  25%
                </Button>
                <Button
                  size="small"
                  color="inherit"
                  onClick={() => {
                    setAmountToStake(Math.floor(maxForVote / 2));
                  }}
                >
                  50%
                </Button>
                <Button
                  sx={{ display: { xs: 'none', md: 'block' } }}
                  size="small"
                  color="inherit"
                  onClick={() => {
                    setAmountToStake(Math.floor(maxForVote / 4 * 3));
                  }}
                >
                  75%
                </Button>
                <Button
                  size="small"
                  color="inherit"
                  onClick={() => {
                    setAmountToStake(maxForVote);
                  }}
                >
                  100%
                </Button>
              </Stack>
              <Stack direction="row" width="100%" justifyContent="center" gap={1}>
                <TextField
                  id="outlined-number"
                  label={t('label.home.selected-project-card.amount')}
                  type="number"
                  size="medium"
                  InputLabelProps={{
                    shrink: true,
                  }}
                  variant="outlined"
                  value={amountToStake.toString()}
                  onChange={changeAmountToStake}
                />
                <Button
                  onClick={() => {
                    doVote(amount);
                  }}
                  disabled={amountToStake > ((contractInfo?.allowance || 0) / 1e18)}
                  color="primary"
                  variant="contained"
                >
                  {t('label.home.selected-project-card.vote')}
                </Button>
              </Stack>
            </Stack>
          )}
          <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={Boolean(loadingMessage)}
          >
            <Stack alignItems="center">
              <CircularProgress color="inherit" title="hello" />
              <Typography>{loadingMessage}</Typography>
            </Stack>
          </Backdrop>
        </Stack>
      </CardActions>
      <VoteConfirmationDialog
        projectCandidate={projectCandidate}
        amount={amount}
        open={dialogOpen}
        onClose={() => setDialogOpen(false)}
      />
    </Card>
  );
});
