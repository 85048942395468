import { QueryClientProvider } from '@tanstack/react-query';
import { I18nextProvider } from 'react-i18next';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { WagmiProvider } from 'wagmi';
import { ThemeProvider } from '@mui/material';
import i18n from './config/i18n.ts';
import { queryClient } from './config/tanquery.ts';
import { config } from './config/wagmi.ts';
import { routes } from './routes/routes.tsx';
import { StoreProvider } from './stores/store-context.tsx';
import { theme } from './config/mui.ts';
import { SnackbarProvider } from './components/SnackbarProvider.tsx';

const router = createBrowserRouter(routes);

export const App = () => (
  <WagmiProvider config={config}>
    <QueryClientProvider client={queryClient}>
      <I18nextProvider i18n={i18n}>
        <StoreProvider>
          <ThemeProvider theme={theme}>
            <SnackbarProvider>
              <RouterProvider router={router} />
            </SnackbarProvider>
          </ThemeProvider>
        </StoreProvider>
      </I18nextProvider>
    </QueryClientProvider>
  </WagmiProvider>
);

export default App;
