import { Instance, SnapshotIn, types } from 'mobx-state-tree';
import { Address } from 'abitype';

export const TransactionModel = types.model('Transaction', {
  id: types.identifier,
  createdAt: types.optional(types.Date, () => new Date()),
  hash: types.maybe(types.string),
  status: types.string,
  type: types.string,
});

export type Transaction = Instance<typeof TransactionModel>;
export type TransactionSnapshot = SnapshotIn<typeof TransactionModel>;

export const TransactionsStoreModel = types
  .model('TransactionsStore', {
    transactions: types.array(TransactionModel),
  })
  .views((self) => ({
    get sortedTransactions() {
      return [...self.transactions].sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
    },
  }))
  .views((self) => ({
    get activeTransactions() {
      return self.sortedTransactions.filter((t) => t.status !== 'idle' && t.status !== 'confirmed');
    },
    getTransactionById(id: string) {
      return self.transactions.find((t) => t.id === id);
    },
    getTransactionByHash(hash: Address) {
      return self.sortedTransactions.find((t) => t.hash === hash);
    },
  }))
  .actions((self) => ({
    addTransaction(transaction: TransactionSnapshot) {
      self.transactions.push(transaction);
    },

    removeTransaction(id: string) {
      const index = self.transactions.findIndex((t) => t.id === id);
      if (index >= 0) {
        self.transactions.splice(index, 1);
      }
    },

    setTransactionStatus(id: string, status: string) {
      const transaction = self.transactions.find((t) => t.id === id);
      if (transaction) {
        transaction.status = status;
      }
    },

    setTransactionHash(id: string, hash: string) {
      const transaction = self.transactions.find((t) => t.id === id);
      if (transaction) {
        transaction.hash = hash;
      }
    },
  }));

export type TransactionsStore = Instance<typeof TransactionsStoreModel>;
