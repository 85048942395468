import React, { useContext, useEffect, useState } from 'react';
import { RootStore } from './root-store.ts';
import { setupRootStore } from './setup-root-store.ts';

const StoreContext = React.createContext<RootStore | undefined>(undefined);
export const useStore = () => {
  const store = useContext(StoreContext);
  if (store === undefined) {
    throw new Error('useStore must be used within a StoreProvider');
  }

  return store;
};

export const StoreProvider = ({ children }: React.PropsWithChildren) => {
  const [rootStore, setRootStore] = useState<RootStore>();

  useEffect(() => {
    setupRootStore().then(setRootStore);
  }, []);

  if (!rootStore) {
    return <div>...</div>;
  }

  return <StoreContext.Provider value={rootStore}>{children}</StoreContext.Provider>;
};
