import { lighten } from '@mui/system';

export const AMOUNT_MULTIPLICATOR_COEF_1 = 1;
export const AMOUNT_MULTIPLICATOR_COEF_100 = 100;
export const AMOUNT_MULTIPLICATOR_COEF_100_000 = 100000;

export const DEFAULT_DECIMAL_NB = 2;

export const PROJECT_BASE_URL = import.meta.env.VITE_PROJECT_BASE_URL;
export const MINIMUM_AMOUNT_TO_VOTE = 2000 * 1e18;

export const SDG: {[key:string]: {link:string, color: string}} = {
  sdg01: {
    link: 'https://www.un.org/sustainabledevelopment/poverty/',
    color: lighten('#E5243B', 0.5),
  },
  sdg02: {
    link: 'https://www.un.org/sustainabledevelopment/hunger/',
    color: lighten('#DDA63A', 0.5),
  },
  sdg03: {
    link: 'https://www.un.org/sustainabledevelopment/health/',
    color: lighten('#4C9F38', 0.5),
  },
  sdg04: {
    link: 'https://www.un.org/sustainabledevelopment/education/',
    color: lighten('#C5192D', 0.5),
  },
  sdg05: {
    link: 'https://www.un.org/sustainabledevelopment/gender-equality/',
    color: lighten('#FF3A21', 0.5),
  },
  sdg06: {
    link: 'https://www.un.org/sustainabledevelopment/water-and-sanitation/',
    color: lighten('#26BDE2', 0.5),
  },
  sdg07: {
    link: 'https://www.un.org/sustainabledevelopment/energy/',
    color: lighten('#FCC30B', 0.5),
  },
  sdg08: {
    link: 'https://www.un.org/sustainabledevelopment/economic-growth/',
    color: lighten('#A21942', 0.5),
  },
  sdg09: {
    link: 'https://www.un.org/sustainabledevelopment/infrastructure-industrialization/',
    color: lighten('#FD6925', 0.5),
  },
  sdg10: {
    link: 'https://www.un.org/sustainabledevelopment/inequality/',
    color: lighten('#DD1367', 0.5),
  },
  sdg11: {
    link: 'https://www.un.org/sustainabledevelopment/cities/',
    color: lighten('#FD9D24', 0.5),
  },
  sdg12: {
    link: 'https://www.un.org/sustainabledevelopment/sustainable-consumption-production/',
    color: lighten('#BF8B2E', 0.5),
  },
  sdg13: {
    link: 'https://www.un.org/sustainabledevelopment/climate-change-2/',
    color: lighten('#3F7E44', 0.5),
  },
  sdg14: {
    link: 'https://www.un.org/sustainabledevelopment/oceans/',
    color: lighten('#0A97D9', 0.5),
  },
  sdg15: {
    link: 'https://www.un.org/sustainabledevelopment/biodiversity/',
    color: lighten('#56C02B', 0.5),
  },
  sdg16: {
    link: 'https://www.un.org/sustainabledevelopment/peace-justice/',
    color: lighten('#009EDB', 0.5),
  },
  sdg17: {
    link: 'https://www.un.org/sustainabledevelopment/globalpartnerships/',
    color: lighten('#19486A', 0.5),
  },
};

export const IAPR_TARGET = {
  name: 'Unitlife',
  link: 'retreeb.com/blog/unitlife-fight-against-malnutrition',
};
