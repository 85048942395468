import { Buffer } from 'buffer';
import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App.tsx';

import './reset.css';
import './unreset.css';
import './index.css';

import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import '@fontsource/azeret-mono/300.css';
import '@fontsource/azeret-mono/700.css';

globalThis.Buffer = Buffer;

ReactDOM.createRoot(document.getElementById('root')!).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
