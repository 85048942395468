import {
  styled, Tooltip, tooltipClasses, TooltipProps,
} from '@mui/material';

export const CustomTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}, & .${tooltipClasses.tooltip} a:visited, & .${tooltipClasses.tooltip} a:link`]: {
    color: theme.palette.text.primary,
  },
}));
