import { ThemeOptions } from '@mui/material/styles';
import { createTheme, responsiveFontSizes } from '@mui/material';
import { createBreakpoints } from '@mui/system';
import createPalette from '@mui/material/styles/createPalette';

const breakpoints = createBreakpoints({});
export const colors = {
  greenLantern: '#94E0E0',
  purpleCraze: '#D398EC',
  orangeMave: '#FFC8AA',
  buff: '#fff2a0',
  lightBlue: '#b6e2ff',
  pearlAqua: '#8fdabb',
  cornflowerLilac: '#ffacb0',
};

const palette = createPalette({
  mode: 'dark',
  background: {
    default: '#1D1D1B',
    paper: '#2C2B2B',
  },
  primary: {
    main: colors.greenLantern,
  },
  secondary: {
    main: colors.purpleCraze,
  },
  error: {
    main: '#D32F2F',
  },
  warning: {
    main: '#EF6C00',
  },
  info: {
    main: '#D7EDFF',
  },
  text: {
    primary: '#FFFFFF',
    secondary: '#FFFFFF99',
    disabled: '#FFFFFF61',
  },
});

const spacing = 8;

const themeOptions: ThemeOptions = {
  breakpoints,
  palette,
  typography: {
    h1: {
      fontFamily: '"Azeret", sans-serif',
      fontSize: 96,
      fontWeight: 600,
    },
    h2: {
      fontFamily: '"Azeret", sans-serif',
      fontSize: 60,
      fontWeight: 600,
    },
    h3: {
      fontFamily: '"Azeret", sans-serif',
      fontSize: 48,
      fontWeight: 600,
    },
    h4: {
      fontFamily: '"Azeret", sans-serif',
      fontSize: 34,
      fontWeight: 600,
    },
    h5: {
      fontFamily: '"Azeret", sans-serif',
      fontSize: 24,
      fontWeight: 600,
    },
    h6: {
      fontFamily: '"Azeret", sans-serif',
      fontSize: 20,
      fontWeight: 700,
    },
    body1: {
      fontFamily: '"Azeret Mono", sans-serif',
      lineHeight: '150%',
      letterSpacing: 0.15,
      fontSize: 16,
      fontWeight: 400,
      [breakpoints.down('md')]: {
        fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
      },
    },
    body2: {
      fontFamily: '"Azeret Mono", sans-serif',
      fontSize: 14,
      fontWeight: 400,
      lineHeight: '143%',
      letterSpacing: 0.17,
      [breakpoints.down('md')]: {
        fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
      },
    },
    subtitle1: {
      fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
      fontSize: 16,
      fontWeight: 400,
    },
    subtitle2: {
      fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
      fontSize: 14,
      fontWeight: 500,
    },
    overline: {
      fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
      fontSize: 12,
      fontWeight: 400,
    },
    caption: {
      fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
      fontSize: 12,
      fontWeight: 400,
    },
    button: {
      fontWeight: 500,
      fontSize: 15,
    },

    fontFamily: '"Poppins", "Helvetica", "Arial", sans-serif',
    fontSize: 16,
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          // textTransform: 'none',
          ...(ownerState.size === 'large' && {
            height: '48px',
          }),
          whiteSpace: 'nowrap',
        }),
      },
    },
    MuiChip: {
      styleOverrides: {
        label: () => ({
          fontSize: '14px',
          display: 'inline-flex',
          alignItems: 'center',
          height: '20px',
        }),
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: ({ ownerState }) => ({
          height: '38px',
          ...(ownerState.size === 'medium' && {
          }),
        }),
      },
    },
    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundImage: 'unset',
          backgroundColor: '#1D1D1B',
          '& .MuiToolbar-root': {
            height: '80px',
            [breakpoints.up('xs')]: {
              height: '60px',
            },
            [breakpoints.up('sm')]: {
              height: '80px',
            },
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: ({ ownerState }) => ({
          ...(ownerState.color === 'common.white' && {
            color: palette.common.white,
            '&.Mui-active': {
              color: palette.common.white,
            },
            '&.Mui-completed': {
              color: palette.common.white,
            },
          }),
        }),
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          paddingTop: 2 * spacing,
          paddingBottom: 2 * spacing,
          paddingLeft: spacing,
          paddingRight: spacing,
        },
      },
    },
  },
  shape: {
    borderRadius: 4,
  },
  spacing,
};

export const theme = responsiveFontSizes(createTheme(themeOptions), {
  factor: 10,
});
