import {
  Card, CardContent, Grid, Stack, Typography,
} from '@mui/material';
import { Trans } from 'react-i18next';
import { ProjectCandidate } from '../types/elections-store.ts';
import { SDG } from '../constants.ts';

type SDGCardProps = {
  projectCandidate: ProjectCandidate
}

export const SDGCard = ({ projectCandidate }: SDGCardProps) => {
  const tags = (projectCandidate.project.projectTags ? projectCandidate.project.projectTags : [])
    .filter((tag) => SDG[tag.slug])
    .sort((a, b) => a.slug.localeCompare(b.slug));

  return (
    <Card>
      <CardContent>
        <Stack alignItems="center">
          <Typography textAlign="center" m={2} sx={{ '& a': { color: 'inherit' } }} maxWidth="300px">
            <Trans i18nKey="label.home.selected-project-card.sdg" values={{ count: tags.length }}>
              project-impacts-on
              <a href="https://sdgs.un.org/goals" target="_blank" rel="noreferrer">SDGs</a>
            </Trans>
          </Typography>
          <Grid justifyContent="center" container flex={1}>
            {tags.map((tag) => tag.slug)
              .map((tag) => (
                <Grid key={tag} item xs={3} display="flex" justifyContent="center">
                  <a href={SDG[tag].link} target="_blank" rel="noreferrer"><img alt={`Goal-${tag}`} width="78" src={`/images/E-WEB-Goal-${tag.substring(3)}.png`} /></a>
                </Grid>
              ))}
          </Grid>
        </Stack>
      </CardContent>
    </Card>
  );
};
